import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import './css/Navbar.css';
import './css/Home.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';
import {  auth } from "../firebase";
import {  useNavigate } from 'react-router-dom';
import Logout from './../Logout';

const NavbarDash = () => {

    return (
        <nav className="navbar fixed-top">
            <div className='container'>
                <div className="">
                <Link to="/my-dash">
                    <img src={logo1} className="" alt="logo" /> 
                </Link>
                </div>
                <div className="navbar-right">
                   <span className='go-to-console fw-bold'>Tableau de bord </span>
                </div>
            </div>
        </nav>
    );
};

export default NavbarDash;