import React from "react";
import "./css/Footer.css"; // Importer le fichier de styles

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer-content">
        <div className="col-md-4 footer-description">
          <p className="fs-3 fw-bolder footer-title">Fleetbo</p>
          <p className="fs-6 fw-normal text-white">
            <b>Empowering Web Developers.</b> <br />
            Des applications mobiles performantes à moindre coût et en temps record. <br /> <br />
            <b>Contact</b> <br />
            contact@fleetbo.io
          </p>
        </div>
        <div className="footer-socials">
          <h4>Suivez-nous</h4>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/fleetbo-io/" className="icon ms-0 ps-0" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://facebook.com" className="icon " target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com" className="icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="container footer-bottom"><p className="footer-copy">&copy; 2024 Fleetbo</p></div>
    </footer>
  );
};

export default Footer;
