import React from 'react';
import './css/Navbar.css';
import assistant from './img/assistant.png';

const NavbarDoc = () => {
  return (

  <nav className="navbar fixed-top">
    <div className='container'>
      <div className="navbar-left">
        <a href="javascript:history.back()">
          <i class="fas fa-arrow-left ms-1 fw-bold text-success"></i>
        </a>
      </div>
      <div className="navbar-right">
        <a href="/login" className="cart-icon">
          <i class="fas fa-envelope me-2 mt-2"></i>
        </a>
        {/*
        <a href="/login" className="cart-icon">
          <i class="fas fa-circle-question fs-4"></i>
        </a>
        */
        }
      </div>
    </div>
  </nav>
  );
};

export default NavbarDoc;