// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // pour Firestore
import { getAuth } from "firebase/auth"; // pour l'authentification
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyAC5ROxI3bnIO1DyNflMhFRrtnR-45p4RE",
    authDomain: "myapp-259bf.firebaseapp.com",
    projectId: "myapp-259bf",
    storageBucket: "myapp-259bf.appspot.com",
    messagingSenderId: "546836125888",
    appId: "1:546836125888:web:067b09b472317fcc8e3525",
    measurementId: "G-7K0CH85RGY"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Si vous utilisez Firestore
const auth = getAuth(app); // Si vous utilisez l'authentification
const storage = getStorage(app); // utilisation du storage

export { db, storage, auth };




