import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import './css/Navbar.css';
import './css/Home.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';
import {  auth } from "../firebase";
import {  useNavigate } from 'react-router-dom';
import Logout from './../Logout';

const NavbarContact = () => {

    return (
        <nav className="navbar fixed-top">
            <div className='container'>
                <div className="navbar-left">
                    <Link to="/">
                       <i class="fas fa-arrow-left ms-1 fw-bolder text-success"></i>
                    </Link>
                </div>
                <div className="navbar-right">
                     <span className='go-to-console'>Nous contacter </span>
                </div>
            </div>
        </nav>
    );
};

export default NavbarContact;