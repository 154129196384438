import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { auth } from './components/firebase';
import Redirect from './components/Pages/Redirect';
import Welcome from './components/Pages/Welcome';
import Welcome_en from './components/Pages/Welcome_en';
import Login from './components/Pages/Login';
import Notification from "./components/Pages/push/notification";
import Message from "./components/Pages/push/message";
import Contact from './components/Pages/Contact';
import Inscription from './components/Pages/Inscription';
import Documentation from './components/Pages/Documentation';
import Publisher from './components/Pages/Publisher';
import Pub from './components/Pages/video/Pub';
import Home from './components/Pages/Home';
import Project from './components/Pages/project/Project';

import NewProject from './components/Pages/project/Create';
import Launch from './components/Pages/subscribe/Launch';
import Scale from './components/Pages/subscribe/Scale';
import Dashboard from './components/Pages/admin/Dashboard';
import Contacts from './components/Pages/admin/Contacts';
import Messages from './components/Pages/Messages';
import FinishSignIn from './components/Pages/FinishSignIn';
import ProtectedRoute from './components/ProtectedRoute';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState }  from 'react-firebase-hooks/auth';
import Footer from './components/Pages/Footer';
import CookieAlert from './components/Pages/cookie/CookieAlert';
//import useScrollToTop from './hooks/useScrollToTop'; // Importer le hook personnalisé


const PrivateRoute = ({ children }) => {
  return localStorage.getItem('auth') ? children : <Navigate to="/login" />;
};

function App() {

  const [user, loading, error ] = useAuthState(auth);


  return (
    <div>

       <BrowserRouter>
        <Routes>
          <Route path='/my-dash' element={<Dashboard />} />
          <Route path='/pub' element={<Pub />} />
          <Route path='/redirect' element={<Redirect />} />
          <Route index element={<Welcome />} />
          <Route path='/en' element={<Welcome_en />} />
          <Route path='/inscription' element={<Inscription />} />
          <Route path='/join' element={<Contact />} />
          <Route path="/FinishSignIn" element={<FinishSignIn />} />
          <Route path='/login' element={<Login />} />
          <Route path='/home1' element={<Home />} />
          <Route path='/project/:id' element={<Project />} />
          <Route path='/new-project/:id' element={<NewProject />} />
          <Route path='/notification' element={<Notification />} />
          <Route path='/documentation' element={<Documentation />} />
          <Route path='/launch' element={<Launch />} />
          <Route path='/scale' element={<Scale />} />
          <Route path='/Publisher' element={<Publisher />} />
          <Route path='/message' element={<Message />} />
          <Route 
                path="/home" 
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                } 
          />
		  <Route 
                path="/contacts" 
                  element={
                    <ProtectedRoute>
                      <Contacts />
                    </ProtectedRoute>
                } 
          />
         
          <Route path='/messages' element={<Messages />} />
        </Routes>
       </BrowserRouter>
       <CookieAlert />  {/* Ajouter l'alerte de cookies ici */}
    </div>
  );
}

export default App;
