import React, { useEffect, useState } from 'react';
import './css/Welcome.css';
import './css/Login.css';
import { Player } from 'video-react';

import Footer from './Footer';
import Navbar from './navbars/NavbarLogin';
import { auth } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthState }  from 'react-firebase-hooks/auth';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useLocation, BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';


function Login() {

    const navigate = useNavigate();

    const [user] = useAuthState(auth);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');


    const { pathname } = useLocation(); // Récupère le chemin de la route actuelle
    useEffect(() => {
      // Défile immédiatement au sommet sans animation
      window.scrollTo(0, 0);
    }, [pathname]); // Appelle window.scrollTo à chaque changement de route


    if(user){
      navigate('/redirect');
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            setError('Email ou mot de passe incorret.');
        }
    };

	return (
		<div className="App" id='id'>
		  <Navbar />
		  <header className="App-header">
			<h5 className='Brand-FleetBo mt-2'> Fleetbo <sup className='beta fw-bold'>Beta</sup> </h5>
			<div className='col-md-6 ps-2 pe-2'>
				<p className='box-subtitle'>
					<span className='title1'>
						<span className='brand'>Empowering web developers</span> 
					</span>
					<br />
				</p> 
			</div>

			<div className='box-form text-center justify-content-center'>

				{!user ? (
				  <div className='p-0'>
					
					<form onSubmit={handleLogin}>
					  <div className=' p-0 m-0 justify-content-center'>
							<div className=' mb-2'>
							  <label className='ps-0 fs-6 label'>E-mail</label>
							  <input type="email" 
									className='form-control' 
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</div>
							<div className=' mb-4'>
							  <label className='ps-0 fs-6 label'>Password</label>
							  <input type="password" 
									 value={password} className='form-control'
									 onChange={(e) => setPassword(e.target.value)}
									 required
								/>
							</div>

							<div className='mt-2 mb-2 justify-content-center d-flex'>
							  <button type='submit' className='connexion' >  Sign In </button>
							</div>
						    <div className='mb-3 justify-content-center d-flex'>
							  <Link to='/contact' className='inscription' >  Sign up now </Link>
							</div>
							<div className='mb-3 justify-content-center d-flex'>
								 <h6 className='text-light bg-transparent'><b>Fleetbo</b> &copy; 2024</h6> 
							</div>
					  </div>
					</form>
				  </div>
				  
				) : (
				  <p>Welcome, {user.email}</p>
				)}

			</div>

			 {/* show login error msg */}
			 {/*}
			 {loginError!==''&&(
				<div className='error-msg'>{loginError}</div>
			  )}
			*/}

			{error && <p className='fs-6'>{error}</p>}
		  </header>

		</div>
	);
}

export default Login;
