import React, { useState, useEffect } from 'react';
import './../css/Welcome.css';
import Navbar from './../navbars/Navbar';
import logo1 from './../img/logo1.png';


function Pub() {

    // Le texte que tu veux afficher lettre par lettre
    const texte1 = "Grace à Fleetbo, développez des applications mobiles natives avec JavaScript.                  Une native app aux modalités d'une web app.";
    const texte2 = "Qu'en dites-vous ?";

    // États pour gérer l'affichage des textes
    const [affichage1, setAffichage1] = useState('');
    const [affichage2, setAffichage2] = useState('');
    const [index1, setIndex1] = useState(0);
    const [index2, setIndex2] = useState(0);
    const [started, setStarted] = useState(false); // Pour démarrer le premier texte
    const [secondTextStarted, setSecondTextStarted] = useState(false); // Pour démarrer le second texte après 1 minute
    const [resetAll, setResetAll] = useState(false); // Pour redémarrer le tout après 3 secondes

    // useEffect pour le premier texte (lettre par lettre)
    useEffect(() => {
    if (!started) {
        // Démarrer après 3 secondes
        const startTimer = setTimeout(() => {
        setStarted(true);
        }, 2000); // Attendre 3 secondes avant de démarrer
        return () => clearTimeout(startTimer);
    } else if (index1 < texte1.length) {
        // Ajouter une lettre toutes les 5 secondes pour le premier texte
        const timer = setTimeout(() => {
        setAffichage1((prev) => prev + texte1[index1]);
        setIndex1(index1 + 1);
        }, 70); // 5000 millisecondes = 5 secondes par lettre
        return () => clearTimeout(timer);
    } else if (index1 === texte1.length && !secondTextStarted) {
        // Une fois que le premier texte est terminé, attendre 60 secondes pour le deuxième texte
        const startSecondTextTimer = setTimeout(() => {
        setSecondTextStarted(true);
        }, 2000); // Attendre 60 secondes = 1 minute
        return () => clearTimeout(startSecondTextTimer);
    }
    }, [index1, started, secondTextStarted, texte1]);

    // useEffect pour le deuxième texte (lettre par lettre)
    useEffect(() => {
    if (secondTextStarted && index2 < texte2.length) {
        // Ajouter une lettre toutes les 5 secondes pour le deuxième texte
        const timer = setTimeout(() => {
        setAffichage2((prev) => prev + texte2[index2]);
        setIndex2(index2 + 1);
        }, 50); // 5000 millisecondes = 5 secondes par lettre
        return () => clearTimeout(timer);
    } else if (secondTextStarted && index2 === texte2.length) {
        // Une fois que le deuxième texte est terminé, attendre 3 secondes avant de tout réinitialiser
        const resetTimer = setTimeout(() => {
        setAffichage1('');
        setAffichage2('');
        setIndex1(0);
        setIndex2(0);
        setStarted(false);
        setSecondTextStarted(false);
        setResetAll(true);
        }, 5000); // Attendre 3 secondes avant de redémarrer tout
        return () => clearTimeout(resetTimer);
    }
    }, [index2, secondTextStarted, texte2]);


    return (
        <div className="App">
        <Navbar />
        <header className="App-header">
            <div className='col-md-6 pt-3 pb-3 m-3' style={{ height: '270px' }}>
                <div className='row ' style={{ height: '120px' }}>
                    <h2 className='fw-bolder text-white' style={{ fontSize: '60px' }}>
                       <img src={logo1} style={{ height: '85px', width: '90px'  }} className="" alt="logo" /> 
                       <span className='pt-5 ms-2'>Fleetbo.io</span>
                    </h2>
                </div>
                <div className='row pt-3 ps-2 pe-2 pb-3 bg-white' style={{ borderRadius: '10px' }}>
                    <div className='row' style={{ height: '65px', float: 'left', marginBottom: '12px'  }}>
                        <h4 className='text-dark' style={{ fontSize: '20px', textAlign: 'left', fontWeight: '520', color: '#05b365', lineHeight: '27px' }}> {affichage1}</h4>
                    </div>
                    <div className='row' style={{ height: '30px', float: 'left'  }}>
                        <h4 className='' style={{ fontSize: '20px', textAlign: 'left', fontWeight: '520', color: 'rgb(2, 119, 39)', lineHeight: '30px' }}> {affichage2}</h4>
                    </div>
                </div>  
            </div>
        </header>
        </div>
    );
}

export default Pub;