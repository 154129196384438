import './css/Messages.css';
import { Player } from 'video-react';
import { useState } from 'react';

import Navbar from './navbars/NavbarMessages';


function Home() {


  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <h1 className='fs-3 fw-bolder text-light title'>  Studely </h1>
        <div className='col-md-9 pt-1 pb-1 offre'>
            <div class="container">
                <div class="col-md-12 justify-content-center">
                    
                    <div class="col-md-12 box ps-3 pe-3  pb-5 ">
                        <h5 className='text-dark fs-4 fw-bold mt-3 mb-3'>  
                            Boite de reception
                            <a href='/message' className='ms-2'>
                              <b className='text-success ms-2'><i className="fas fa-feather"></i></b> 
                            </a>
                        </h5>
                        <div className='col-md-12 div-details ps-2 pe-2 pt-3 mb-1'>
                            <div className='mb-4'>
                                <h5 className='description'>
                                   <span className='text-success fw-bold me-2'> eleanorr_id</span>
                                    Plus besoin d'applications  Android / IOS / web  eqrgerqvg qrg qehbsq qehg shs  
                                </h5>  
                                <h5 className='target text-dark fw-normal'> 
                                    11 Juillet 2024    
                                    <a className='fw-bold text-dark ms-3' href=''><i class="fa-solid fa-box-open"></i> Ouvrir</a>
                                   
                                </h5>
                            </div>
                            <hr></hr>
                            <div className='mb-4'>
                                <h5 className='description'>
                                   <span className='text-success fw-bold me-2'> eleanorr_id</span>
                                    Plus besoin d'applications  Android / IOS / web  eqrgerqvg qrg qehbsq qehg shs  
                                </h5>  
                                <h5 className='target text-dark'> 
                                    <a className='fw-bold text-dark me-3' href=''><i class="fa-solid fa-box-open"></i> Ouvrir</a>
                                    05 Juillet 2024    
                                </h5>
                            </div>
                            <br />
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>

        <div className='ps-3 pe-3'>
           <p className='text-desc'>Donnez à vos sites web l'expérience d'une appli.</p>
        </div>

      </header>


      <footer className="footer pt-2 pb-1">
        <div>
           <h6 className='text-success'><b>FleetBo</b> &copy; 2024</h6> 
        </div>
      </footer>
      
    </div>
  );
}

export default Home;
