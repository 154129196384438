import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import './css/Navbar.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';

const NavbarEn = () => {
    return (
    <nav className="navbar fixed-top">
        <div className='container'>
            <div className="">
                <Link to="/">
                    <img src={logo1} className="" alt="logo" /> 
                </Link>
            </div>
            <div className="navbar-right">
                <Link to="/home" className="go-to-console fw-bolder me-3" aria-label="User Account">
                    <i class="fas fa-tachometer-alt fs-5 me-2"></i>
                </Link>
                {/**
                 * 
                <a href="mailto:contact@fleetbo.io" className="cart-icon">
                <img src={assistant} className="assistant" alt="logo" /> 
                </a>
                */}
                <a href="mailto:contact@fleetbo.io" className="cart-icon me-3">
                    <i class="fas fa-envelope fs-5 me-3"></i>
                </a>
                <Link to="/" className="go-to-console fw-bolder" aria-label="User Account">
                    Fr 
                </Link>
            </div>
        </div>
    </nav>
    );
};

export default NavbarEn;