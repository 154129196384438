import React, { useEffect, useState } from "react";
import './css/Welcome.css';
import NavbarHome from './navbars/NavbarHome';
import NavbarLogin from './navbars/NavbarLogin';
import { db, auth } from "./firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useAuthState }  from 'react-firebase-hooks/auth';
import { useNavigate, Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Modal, Button } from 'react-bootstrap';


function Home() {


  //const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [use ] = useAuthState(auth);
  const navigate = useNavigate();


  useEffect(() => {
	  
	if(use){
      ///navigate('/redirect');
    }
	
	const checkAdmin = async () => {
      const id = "6OV6nwVNb7U2gUyieRbCGiWAfRB3"; // ID du document
      const docRef = doc(db, "admin", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        navigate('/contacts');
      } 
    };
	
	if (use) {
      checkAdmin();
    }

    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'entreprises'));
        const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Sort items in ascending order based on dateCreated
        const sortedItems = items.sort((a, b) => a.dateCreated - b.dateCreated);
        
        setData(sortedItems);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
      return <div className='ps-5 pe-5 pb-5 App-header'><><div className="fs-3 fw-normal text-dark p-5">

  </div></> </div>;
  }

  return (
    <>
    {loading? (
      <div>  </div>
    ): (
      <div>
        {use? (
          <NavbarHome />
        ):(
          <NavbarLogin />
        )}
      </div>
    )}

    <div className="" style={{ background: '#02a049', minHeight: '100vh' }}>

        <div className="justify-content-center">
            {loading? (
			  <div>  </div>
			): (
			  <div>
				{use? (
				  <NavbarHome />
				):(
				  <NavbarLogin />
				)}
			  </div>
			)}
			<br /> <br /> <br />
            <div className="container ">
			    <div className="bg-box-title row justify-content-center">
				   <div className="row justify-content-center"><div className="row justify-content-center mb-1"><h4 className='text-white fw-bold'>Mes projets</h4> </div></div>
				</div>
                <div className="row justify-content-center">
                    <>  
					    <Link onClick={handleShow} className="bg-add-project text-center p-1">
							<div  >
								<i className="fas fa-plus text-success fs-1 me-2"></i>
								<h5 className='text-success text-center fw-bold'>New project</h5>
							</div>
						</Link>
						
						<Modal show={show} onHide={handleClose} animation={true} className="modal-custom" centered>
						    <Modal.Header closeButton className="pt-3 pb-2">
								<Modal.Title><h5 className='text-success fw-normal'>Nos formules</h5></Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="container mt-2">
									<div className="row div-formules pb-4 bg-white justify-content-center">
										<div className="col-sm-4 box-features pt-4 ps-4 mt-1">
											<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Developers</b> </h5>
											<div className='box-details-desc pb-3'>
												<span className='details-desc '> Conçue pour tests des developpeurs </span>
											</div>
											<div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>   
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Jusqu'à 15 campagnes le mois. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 500k Push notifications autorisés. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 1 Go de stockage disponibles. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 		
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>				
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Vos tests sur l'app Fleetbo devbo.</h5>       
											</div>
											<div className='box-details-prices'>
												<h3 className='fs-4 fw-bold text-success'> <i className="fa-solid fa-gift me-1"></i> Gratuit </h3>
												<h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> <b className='ms-0 text-dark' style={{fontWeight: '530', fontSize: '13px'}}> 30 jours offerts</b> </h4>
												<h4 className='fs-6 text-success'> <i className="fa-solid fa-toggle-on me-1"></i> <b  style={{ fontWeight: '505'}}>Launch</b> après 30 jours</h4> 
												<Link to="/new-project/developers" className='btn-start bg text-center mt-2'>Démarrer <i className="fas fa-tachometer-alt me-2"></i></Link> 
											</div>
										</div>
										<div className="col-sm-4 box-features bf-launch pt-4 ps-4 ms-4 me-4" style={{ border: '3px solid #05b365;' }}>
											<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Launch</b> </h5>
											<div className='box-details-desc pb-3'>
												<span className='details-desc '> Conçue pour les startups en démarrage </span>
											</div>
											<div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> Jusqu'à 300 campagnes par an. </h5> 
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> 3 M Push notifications par mois. </h5>
												<h5 className='details '><i className="fa-solid fa-check text-success fw-bold me-2"></i> 50 Go de stockage disponibles. </h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5>   
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>  						
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
												<h5 className='details '><i className="fa-solid fa-check me-2"></i> Vos fichiers apk et IPA en 24H.</h5>   
											</div>
											<div className='box-details-prices'>
												<h3 className='fs-4 fw-bold text-success'> 75 &euro;  </h3>
												<h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> 15 &euro; / mois <b className='fs-6 me-1 ms-1 text-dark' style={{fontWeight: '400'}}>OU</b> <span className='text-dark tarif'><sup> 170 &euro; / an</sup></span></h4>
												<h4 className='fs-6 text-success'> <i className="fa-solid fa-gift me-1"></i> Premier mois offert </h4> 
												<Link to="/new-project/launch" className='btn-start bg text-center mt-2'>Démarrer <i class="fas fa-tachometer-alt me-2"></i></Link> 
											</div>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>
					</>
                    {data.map((item, index) => (
                    item.status === false ? (
					<>
					    
						<div key={index} className="bg-box-project pt-3 ps-3 pe-3 pb-0 " >
							<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>{item.nom}</b> </h5>
							<div className='box-details-desc pb-1'>
								<span className='details-desc fw-bold '> Loading... 70% </span>
							</div>
							<div className='box-details-button mt-2'>
								<Link disabled className='disabled mt-3'>
									<Spinner animation="grow" variant="warning" role="status" size="sm">
											<span className="visually-hidden text-dark">Loading...</span>
									</Spinner>
									<Spinner animation="grow" variant="warning" role="status" size="sm" className='ms-1'>
											<span className="visually-hidden text-dark">Loading...</span>
									</Spinner>
									<Spinner animation="grow" variant="warning" role="status" size="sm" className='ms-1'>
											<span className="visually-hidden text-dark">Loading...</span>
									</Spinner>
								</Link>
							</div>
						</div>
	
					</>
                    ) : (
					<>
						<div key={index} className="bg-box-project pt-3 ps-3 pe-3 pb-0 " >
							<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>{item.nom}</b> </h5>
							<div className='box-details-desc pb-1'>
								<span className='details-desc mt-0 pt-0 '> 35 0000 users </span> <br />
								<i className="fa-brands fa-apple fs-6 text-dark float-start mt-2 me-2"></i>
								<i className="fa-brands fa-android fs-6 text-dark float-start mt-2 "></i> 
							</div>
							<div className='box-details-button mt-2'>
								<Link to={`/project/${item.id}`} className='btn-start text-center mt-3'>Ouvrir <i className="fas fa-tachometer-alt me-2"></i></Link>
							</div>
						</div>
						
						<div className="bg-box-project pt-3 ps-3 pe-3 pb-0" >
							<h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>dht</b> </h5>
							<div className='box-details-desc pb-1'>
								<span className='details-desc mt-0 pt-0 '> 35 0000 users </span> <br />
								<i className="fa-brands fa-apple fs-6 text-dark float-start mt-2 me-2"></i>
								<i className="fa-brands fa-android fs-6 text-dark float-start mt-2 "></i> 
							</div>
							<div className='box-details-button mt-2'>
								<Link className='btn-start text-center mt-3'>Ouvrir <i className="fas fa-tachometer-alt me-2"></i></Link>
							</div>
						</div>
				    </>		
                    )
                    ))}
              </div> 
            </div>
        </div> 
    </div>
    </>
  );
}

export default Home;
