import './css/Welcome.css';
import './css/Mobile.css';
import { Player } from 'video-react';
import { useState } from 'react';

import Navbar from './navbars/NavbarNewProject';


function Publisher() {


  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <div className='col-md-11 ms-2 me-2 row justify-content-center'>
            <div class="col-sm-6 p-2">
                <br /> <br />
                <h5 className='mt-5 fw-bolder  ms-2'> FleetBo </h5>
                <div className='col-md-12 ps-2 pe-2 pt-2 pb-2 '>
                    <p className=''>
                      <h1 className='float-left'>Mode de Publication </h1>   
                      <h4 className='text-decoration-underline text-capitalize text-white'>Modes:</h4>
                      <span className='title2 text-light'>
                        Production (public) | Developer (tests) | OffLine (fermé).
                      </span>
                      <br />
                      <div className='col-md-12 mt-3 '>
                          <a href='/login' className='prod bg-success mt-1'>
                            Production <i class="fas fa-globe me-2"></i>
                          </a>
                          <a href='/login' className='developer bg-warning mt-1 ms-1'>
                            Developer <i class="fas fa-globe me-2"></i>
                          </a>
                          <a href='/login' className='offline bg-danger mt-2 ms-1'>
                            OffLine <i class="fas fa-globe me-2"></i>
                          </a> 
                      </div>
                     
                    </p>
                </div>
            </div>
            <div class="col-sm-5 bg-media ">
                 <video width="420" height="305" src="https://assets.codepen.io/6093409/hubspot-video-example.mp4" controls autoplay></video>
            </div>
        </div>
      </header>

      <footer className="footer pt-2 pb-1">
        <div>
           <h6 className='text-success'><b>FleetBo</b> &copy; 2024</h6> 
        </div>
      </footer>
      
    </div>
  );
}

export default Publisher;
