import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import './css/Navbar.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';

const NavbarInscription = () => {
  return (
    <nav className="navbar fixed-top">
      <div className='container'>
          <div className="navbar-left">
            <Link to="/">
              <img src={logo1} className="" alt="logo" /> 
            </Link>
          </div>
          <div className="navbar-right">
            <Link to="/login" className="go-to-console text-success fw-bold me-2">
                Se connecter
            </Link>
            <Link to="/documentation" className="cart-icon">
                <img src={assistant} className="assistant" alt="logo" /> 
            </Link>
          </div>
      </div>
    </nav>
  );
};

export default NavbarInscription;