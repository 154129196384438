import React, { useEffect, useState } from 'react';
import './css/Contact.css';
import Navbar from './navbars/NavbarContact';
import Footer from './Footer';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuth } from '../firebase/AuthContext';
import {
    collection,
    addDoc,
	query, 
	where,
    serverTimestamp,
    getDocs,
    updateDoc,
    deleteDoc,
    doc
  } from 'firebase/firestore';
import { useForm } from 'react-hook-form';



function Contact() {

    const [message, setMessage] = useState('');
	const [error, setError] = useState('');

    const [user, setUsers] = useState(null);
    
    const [linkedin, setLinkedin] = useState('');
    const [country, setCountry] = useState('');
    const [text, setText] = useState('');
    const [email, setEmail] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		setMessage('');

		try {
			// Vérifie si l'email existe déjà
			const emailQuery = query(
				collection(db, 'contacts'),
				where('email', '==', email)
			);

			const querySnapshot = await getDocs(emailQuery);

			if (!querySnapshot.empty) {
				// Si des documents avec le même email existent
				setError('This email address already exists.');
				return;
			}

			// Si l'email n'existe pas, ajoute le nouveau contact
			await addDoc(collection(db, 'contacts'), {
				email: email,
				country: country,
				linkedin: linkedin,
				contactDate: serverTimestamp(),
			});

			setMessage("You're a fleetbo developer.");
			// Réinitialise les champs après soumission réussie
			setCountry('');
			setEmail('');
			setLinkedin('');
		} catch (error) {
			setError(`Error: ${error.error}`);
		}
	};


    const handleCountryChange = (event) => {
     setCountry(event.target.value);
    };

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <h5 className='Brand-FleetBo mt-2'> Fleetbo <sup className='beta fw-bold'>Beta</sup> </h5>
        <div className='col-md-4 ps-2 pe-2'>
            <p className='box-subtitle'>
              <span className='title1'>
                  <span className='brand'>Join the fleetbo community of developers</span> 
              </span>
              <br />
            </p> 
        </div>

        <div className='col-md-3 text-center justify-content-center'>
            {user ? (
              <div>
                <p>Welcome, {user.email}</p>
                <button >Logout</button>
              </div>
            ) : (
            <form onSubmit={handleSubmit}>
              <div className='justify-content-center'>
                    
                    <div className=''>
                        <label className='ps-0 label'>E-mail</label>
                        <input type="email" 
                               className='form-control input'
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               required
                        />
                    </div>

                    <div className='mb-1'>
                        <label className='ps-0 label'>Country</label>
                        <select id="country" className='form-control input' value={country} onChange={handleCountryChange}>
                            <option value="">Selectionner</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
							<option value="France">France</option>
                            <option value="Ivory Coast">Ivory Coast</option>
							<option value="Germany">Germany</option>
                            <option value="Suisse">Suisse</option>
							<option value="Togo">Togo</option>
							<option value="US">US</option>
                        </select>
                    </div>
					
					<div className='mb-4'>
                        <label className='ps-0 label'>Link to linkedin profile</label>
                        <input type="url" 
                               className='form-control input'
                               value={linkedin}
                               onChange={(e) => setLinkedin(e.target.value)}
                               required
                        />
                    </div>


                    {/*

					<div className='mb-4'>
                        <label className='ps-0 label'>Stack (JS developers, React JS etc)</label>
                        <input type="text" 
                               className='form-control input'
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                               required
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='ps-0 label'>Presentation (200 caractères)</label>
                        <textarea type="text" 
                                  className='form-control input' 
                                  id="exampleFormControlTextarea1"
                                  rows="3"
								  maxLength="200"
                                  value={text}
                                  onChange={(e) => setText(e.target.value)}
                                  required
                        >
                        </textarea>
                    </div>
					*/}
                    <div className='col-md-12 mb-3 justify-content-center d-flex'>
                      {/* <a href='/login' className='inscription me-2' >  Connexion </a> */}
                      <button type='submit' className='connexion' >  Join the community </button>
                    </div>
					{error && 
                     <p className='form-info fw-bold text-danger text-center'><i className="fa-regular fa-circle-xmark fs-6"></i> {error} </p>
                    } 
                    {message && 
                     <p className='form-info text-light text-center'><i className="fa-regular fa-face-smile fs-6"></i> {message}</p>
                    } 
					
              </div>
            </form>
            )}
        </div>

      </header>

      <Footer />
      
    </div>
  );
}

export default Contact;
