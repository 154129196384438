import logo1 from './../img/logo1.png';
import './../css/Welcome.css';
import './../css/Mobile.css';
import { Player } from 'video-react';
import { useState } from 'react';

import Navbar from '../navbars/NavbarNewProject';


function Launch() {


  return (
    <div className="App">
      <Navbar />

      <div className='justify-content-center offre'>
        <h3 className='formule pb-3 '><b className='text-success'>Fleetbo</b> Launch </h3>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-6 box-features pt-3 ps-3 pe-3 pb-2">
                    <form>
                      <div className='col-md-12 div-details-welcome  ps-2 pe-2  pb-3'>

                          <h5 className='text-success fw-bolder mt-3'> 1. Projet</h5>
                          <br /> 

                          <span className='title1'>
                              <span className='brand fw-bold text-decoration-none text-success'> Nom du projet</span> 
                          </span>
                          <div className='input-box'>
                              <h5 className='desc-input'> Le nom du projet en 50 caractères maximum.</h5>
                              <div className='mt-3'>
                                  <input type="text" 
                                    className='form-control mt-2 input' placeholder='Nom du projet'
                                  />
                              </div>
                          </div>
                          <br /> 

                          <span className='title1'>
                              <span className='brand fw-bold text-decoration-none text-success'> Description du projet</span> 
                          </span>
                          <h5 className='desc-input'> Présentez votre projet en 200 caractères maximum.</h5>
                          <div className='mt-3 '>
                              <textarea type="text" 
                                className='form-control mt-2 input' placeholder=''
                                
                              />
                          </div>
                          <br /> 

                          

                          {/* 2 customization */}
                          <h5 className='text-success fw-bolder mt-3'> 2. Personnalisation</h5>
                          <br /> 
                          <span className='title1'>
                              <span className='brand fw-bold text-decoration-none text-success'> Thème du projet</span> 
                          </span>
                          <h5  className='desc-input '> Charte graphique ou couleur dominante du projet en hex. <span className='fs-6 fw-bold'>Ex: #854858</span></h5>
                          <div className='mt-3'>
                              <input type="text" className='form-control mt-2 input' placeholder=''   />
                          </div>
                          <br /> 

                          <span className='title1'>
                              <span className='brand fw-bold text-decoration-none text-success'> Logo du projet</span> 
                          </span>
                          <h5  className='desc-input'> Le logo de votre projet avec la taille <span className='fs-6'>512px * 512px</span> </h5>
                          <div className='mt-3'>
                              <input type="file" className='form-control mt-2 input'  />
                          </div>
                          <br />



                          {/* 3 .APK & .IPA */}
                          <h5 className='text-success fw-bolder mt-4'> 3. Fichiers .apk et .ipa</h5>
                          <br /> 
                          <span className='title1'>
                              <span className='brand fw-bold text-decoration-none text-success'> Projet React js buildé</span> 
                          </span>
                          <h5 className='desc-input'>
                            Vous avez terminé le développement de votre projet. <br />  Soumettez nous votre projet buildé: <b>"npm build"</b>
                          </h5>
                          <div className='mt-3'>
                              <input type="file" className='form-control mt-2 input'  />
                          </div>
                          <br />

                          <div className='mt-3'>
                              <button type="file" className='form-control mt-2  fw-bold btn btn-success' > Créer <i class="fa-solid fa-plus"></i> </button>
                          </div>

                        </div>
                    </form>

                </div>
               
            </div>
        </div>
      </div>
    </div>
  );
}

export default Launch;
