import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import './css/Navbar.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';

const NavbarLogin = () => {


  function closeBrowser() {
    alert('Bye Bye AppInBrowser!');
    window.parent.postMessage('closeBrowser', '*'); // Envoie un message à l'application pour fermer l'InAppBrowser
  }

  const closeInAppBrowser = () => {
    alert('Bye Bye AppInBrowser!');
    // Redirection vers l'URL spéciale pour fermer l'InAppBrowser
    window.location.href = 'capacitor://close';
  };


  return (
    <nav className="navbar fixed-top">
      <div className='container'>
          <div className="navbar-left">
            <Link to="/">
               <i class="fas fa-arrow-left fw-bolder text-success"></i>
            </Link>
          </div>
          <div className="navbar-right">
            {/**
            <Link to="/inscription" className="go-to-console text-success fw-bold me-3">
              S'inscrire
            </Link>
	          <Link to="/" className="cart-icon">
              <img src={assistant} className="assistant" alt="logo" /> 
            </Link>
            <Link onClick={closeInAppBrowser} className="cart-icon me-3">
              <i class="fas fa-xmark fs-5 me-3"></i>
            </Link>
            */}
            <a href='/welcome' className="cart-icon">
              <i class="fas fa-envelope fs-5"></i>
            </a>
          </div>
      </div>
    </nav>
  );
};

export default NavbarLogin;