// src/FinishSignIn.js
import React, { useEffect } from 'react';
import { auth } from './firebase';
import { useNavigate  } from 'react-router-dom';

const FinishSignIn = () => {
    const history = useNavigate();

    useEffect(() => {
        const finishSignIn = async () => {
            if (auth.isSignInWithEmailLink(window.location.href)) {
                let email = window.localStorage.getItem('emailForSignIn');
                if (!email) {
                    email = window.prompt('Veuillez entrer votre adresse email pour confirmation.');
                }

                try {
                    const result = await auth.signInWithEmailLink(email, window.location.href);
                    window.localStorage.removeItem('emailForSignIn');
                    history.push('/');
                } catch (error) {
                    console.error('Erreur lors de la finalisation de la connexion:', error);
                }
            }
        };

        finishSignIn();
    }, [history]);

    return (
        <div>
            <h2>Finalisation de la connexion...</h2>
        </div>
    );
};

export default FinishSignIn;