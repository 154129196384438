import React, { useEffect, useState } from 'react';
import './../css/Contact.css';
import Navbar from './../navbars/NavbarNewProject';
import Footer from './../Footer';
import { storage, db } from '../firebase';
import { useAuth } from '../firebase/AuthContext';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useForm } from 'react-hook-form';



function Create() {


    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');
    const [build, setBuild] = useState(null);
    const [icLaunch, setIcLaunch] = useState(null); // 
    const [icNotification, setIcNotification] = useState(null); // 
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			// Upload file to Firebase Storage
			const buildStorageRef = storage.ref(`files/${build.name}`);
			await buildStorageRef.put(build);
			const buildFileURL = await buildStorageRef.getDownloadURL();

			// Upload icon Launch to Firebase Storage
			const launchStorageRef = storage.ref(`files/${icLaunch.name}`);
			await launchStorageRef.put(icLaunch);
			const icLaunchFileURL = await launchStorageRef.getDownloadURL();

			// Upload icon notification to Firebase Storage
			const notificationStorageRef = storage.ref(`files/${icNotification.name}`);
			await notificationStorageRef.put(icNotification);
			const icNotificationFileURL = await notificationStorageRef.getDownloadURL();

			// Save data to Firestore
			await db.collection('entreprises').add({
				name: name,
				description: description,
				color: color,
				icLaunchFileURL: icLaunchFileURL,
				icNotificationFileURL: icNotificationFileURL,
				build: buildFileURL,
				createdAt: db.firestore.FieldValue.serverTimestamp()
			});

			setLoading(false);
			alert('Form submitted successfully!');
		} catch (error) {
			console.error("Error submitting form: ", error);
			setLoading(false);
		}
	};


    return (
      <div className="App" style={{ background: '#02a049' }}>
        <Navbar />
  

        <div className='ps-5 pe-5 pb-5 offre'>
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-sm-6 box-features pt-3 ps-3 pe-3 pb-2 me-4" style={{border: '1px solid #818181'}}>
                      <form>
                        <div className='col-md-12 div-details-welcome  ps-2 pe-2  pb-3'>
                            
							{/* 1 Project */}
                            <h5 className='text-success fw-bolder mt-3'> 1. Project</h5>
                            <br /> 
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> App name</span> 
                            </span>
                            <div className='input-box'>
                                <h5 className='desc-input'> Name (50 characters).</h5>
                                <div className='mt-3'>
                                    <input type="text" 
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                      className='form-control mt-2 input' placeholder=''
                                    />
                                </div>
                            </div>
                            <br /> 
  
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> App description</span> 
                            </span>
                            <h5  className='desc-input'> Description ( 250 Characters ).</h5>
                            <div className='mt-3 '>
                                <textarea type="text" 
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className='form-control mt-2 input' placeholder=''
                                  
                                />
                            </div>
                            <br /> 
  
                            
  
                            {/* 2 customization */}
                            <h5 className='text-success fw-bolder mt-3'> 2. Customization</h5>
                            <br /> 
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Theme</span> 
                            </span>
                            <h5  className='desc-input '> Color <span className='fs-6'>(Ex: #854858)</span></h5>
                            <div className='mt-3'>
								<input type="text" 
                                       value={color}
                                       onChange={(e) => setColor(e.target.value)}
                                       className='form-control mt-2 input' placeholder=''
                                    />
                            </div>
                            <br /> 
  
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Launch icon </span> 
                            </span>
                            <h5  className='desc-input'> Icon <span className='fs-6'> (PNG or JPG & 512px * 512px)</span> </h5>
                            <div className='mt-3'>
                                <input
									type="file"
									className="form-control mt-2 input"
									onChange={(e) => setIcLaunch(e.target.files[0])}
									required
								  />
                            </div>
                            <br />
							
							<span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Notification Icon </span> 
                            </span>
                            <h5  className='desc-input'> Icon <span className='fs-6'>(SVG & 48px * 48px)</span> </h5>
                            <div className='mt-3'>
                                <input
									type="file"
									className="form-control mt-2 input"
									onChange={(e) => setIcNotification(e.target.files[0])}
									required
								  />
                            </div>
                            <br />
  
  

                            {/* 3 .APK & .IPA */}
                            <h5 className='text-success fw-bolder mt-4'> 3. File project</h5>
                            <br /> 
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Build react project</span> 
                            </span>
                            <h5 className='desc-input'> 
                              Submit your build file: <b>"npm run build"</b>. <br /> 
                              <span className='text-dark mt-2'>Build.zip (50 Mo maximum)</span>
                            </h5>
                            <div className='mt-3'>
                                <input
									type="file"
									className="form-control mt-2 input"
									onChange={(e) => setBuild(e.target.files[0])}
									required
								  />
                            </div>
                            <br />
							
							

							<div className='mt-3'>
								<button className='btn-start mt-2  fw-bold' type="submit" disabled={loading}>
									{loading ? 'Submitting...' : 'Create'}
								</button>
                            </div>
							
                          </div>  
                      </form>
  
                  </div>
 
              </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Create;
  