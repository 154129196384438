import React, { useEffect, useState } from 'react';
import './css/Welcome.css';
import './css/Login.css';
import { Player } from 'video-react';
import Navbar from './navbars/NavbarInscription';
import Footer from './Footer';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuth } from '../firebase/AuthContext';
import {
    collection,
    addDoc,
    getDocs,
    updateDoc,
    deleteDoc,
    doc
  } from 'firebase/firestore';
import { useForm } from 'react-hook-form';

function Inscription() {

  const [message, setMessage] = useState('');

  const [user, setUsers] = useState(null);
  const [error, setError] = useState('');

  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [category, setCategory] = useState('');
  const [email, setEmail] = useState('');
  

  const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage('');
  
      try {
        await addDoc(collection(db, 'inscriptions'), { content: name, country, category, email });
        setMessage('Merci de votre contact ! Nous vous répondrons sous peu.');
        setName(''); // Clear the input field after successful submission
        setCountry('');
        setCategory('');
        setEmail('');
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <h1 className='fs-1 title'> FleetBo </h1>
        <div className='col-md-6 ps-2 pe-2'>
            <p className='box-subtitle'>
              <span className='title1'>
                  <span className='brand'>Empowering web developers</span> 
              </span>
              <br />
            </p> 
        </div>

        <div className='col-md-3 text-center justify-content-center'>
            {user ? (
              <div>
                <p>Welcome, {user.email}</p>
                <button >Logout</button>
              </div>
            ) : (
            <form onSubmit={handleSubmit}>
              <div className='justify-content-center'>
                    
                   
                    <div className=' mb-2'>
                        <label className='ps-0 fs-6 label'>Adresse e-mail</label>
                        <input type="email" 
                               className='form-control'
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               required
                        />
                    </div>
                    <div className='mb-2'>
                        <label className='ps-0 fs-6 label'>Localisation (Pays)</label>
                        <input type="text" 
                               className='form-control' placeholder='Pays'
                               value={country}
                               onChange={(e) => setCountry(e.target.value)}
                               required
                        />
                    </div>
                    {/**
                     * 
                    <div className='mb-3'>
                        <select id="category" className='form-control' value={category} onChange={handleCategoryChange}>
                            <option value="">Type de business</option>
                            <option value="Belgique">Belgique</option>
                            <option value="Cameroun">Cameroun</option>
                            <option value="Canada">Canada</option>
                            <option value="Côte d'ivoire">Côte d'ivoire</option>
                            <option value="France">France</option>
                            <option value="Suisse">Suisse</option>
                        </select>
                    </div>
                     */}
                    <div className=' mb-2'>
                        <label className='ps-0 fs-6 label'>Nom de l'application</label>
                        <input type="text" 
                               className='form-control' 
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                               required
                        />
                    </div>
                    <div className='mb-4'>
                        <label className='ps-0 fs-6 label'>Présentation en 200 caractères</label>
                        <input type="text" 
                               className='form-control' 
                               value={category}
                               onChange={(e) => setCountry(e.target.value)}
                               required
                        />
                    </div>
                    {/*** 
                    <div className='mb-3'>
                        <select id="category" className='form-control' value={category} onChange={handleCategoryChange}>
                            <option value="">Type de business</option>
                            <option value="Blog">Blog</option>
                            <option value="Marketplace">Marketplace</option>
                            <option value="Social Network">Réseau social</option>
                            <option value="Landing">Landing Page</option>
                            <option value="Service">Service</option>
                        </select>
                    </div>
                    */}
                    <div className='col-md-12 mb-3 justify-content-center d-flex'>
                      {/* <a href='/login' className='inscription me-2' >  Connexion </a> */}
                      <button type='submit' className='connexion' >  S'inscrire </button>
                    </div>
                    {error && <p>{error}</p>}
                    {message && 
                     <p className='fs-6'>{message}</p>
                    }
              </div>
            </form>
            )}

        </div>
        <div className='ps-3 pe-3'>
            {message && 
               <p className='fs-6'>{message} <i class="fa-regular fa-face-smile"></i></p>
            }
        </div>

      </header>

      <Footer />
      
    </div>
  );
}

export default Inscription;
