import React, { useState, useEffect } from 'react';
import './../css/Cookie.css';

const CookieAlert = () => {
  // Utiliser localStorage pour stocker l'acceptation des cookies
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Vérifier si l'utilisateur a déjà accepté les cookies
    const accepted = localStorage.getItem('cookiesAccepted');
    if (accepted) {
      setTimeout(() => {
        setCookiesAccepted(true);
      }, 1500); // 500ms de délai
    } else {
      // Attendre que la page soit complètement chargée
      window.onload = () => {
        // Ajouter un petit délai pour l'affichage (facultatif)
        setTimeout(() => {
          setShowAlert(true);
        }, 3000); // 500ms de délai
      };
    }
  }, []);

  const handleAcceptCookies = () => {
    // Sauvegarder l'acceptation des cookies dans le localStorage
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
  };

  // Si l'utilisateur a accepté les cookies, ne pas afficher l'alerte
  if (cookiesAccepted) return null;

  return (
    <div className='cookieBox'>
      <i class="fa-solid fa-cookie text-light float-left"></i>
      <p className='text-white fw-normal'> <br /> <br />
         <h4 className='fw-bolder' style={{ color: '#00ff8c' }}>I am Cookies</h4>
         <br />
         Vous êtes sur fleetbo.io, nous utilisons des cookies <b>pour améliorer votre expérience</b>. <br />En continuant à naviguer, vous acceptez notre utilisation des cookies.
      </p>
      <button className="fw-bold" onClick={handleAcceptCookies} style={styles.button}>
        Accepter <i class="fa-solid fa-check"></i>
      </button>
    </div>
  );
};

const styles = {
  cookieAlert: {
    position: 'fixed',
    bottom: 0,
    width: '22%',
    fontSize: '14px',
    backgroundColor: '#fff',
    color: '#414141',
    padding: '10px',
    textAlign: 'center',
    zIndex: 1000,
  },
  button: {
    backgroundColor: '#4CAF50',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    width: '100%',
    BiBorderRadius: '20px',
    fontSize: '17px',

    cursor: 'pointer',
  },
};

export default CookieAlert;
