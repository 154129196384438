import logo1 from './img/logo1.png';
import './css/Welcome.css';
import './css/Mobile.css';
import './css/Documentation.css';
import { Player } from 'video-react';
import { useState } from 'react';

import Navbar from './navbars/NavbarDoc';


function Mobile() {


  return (
    <div className="App bg-documentation">
      <Navbar />

      <div className='box-doc-title mb-0 justify-content-center text-center'>
         <h4 className='formule mt-4'>Documentation <b className='text-success'>FleetBo </b> </h4>
      </div>

      <div className='box-doc'>
        <div class="container">
            <div className='row justify-content-center'>
                <div class="col-sm-5 pb-3"> 
                    <video width="420" height="305" src="https://assets.codepen.io/6093409/hubspot-video-example.mp4" controls autoplay></video>
                </div>
            </div>
            <div class="row justify-content-center mt-3">
                <div class="col-sm-6 box-features p-3 ">
                    <div className='col-md-12 div-details-welcome ps-2'>
                        <h5 className='text-success fw-bold mt-3'> Structure</h5>
                        <span className='title1'>
                            <span className='brand fw-bold text-decoration-none text-dark'> Créer un projet avec Javascript: <b>Vue JS</b> ou <b>React JS</b> </span> 
                        </span>
                        <h5 className='details '><i class="fa-solid fa-check me-2 mt-2"></i> Développez votre projet avec Vue Js ou React JS.</h5>
                        <h5 className='details '><i class="fa-solid fa-check me-2"></i> Assurez-vous de développer vos interfaces en mode mobile. </h5>
                        <h5 className='details'><i class="fa-solid fa-check me-2"></i>  Créez un dossier <b className='text-decoration-underline'>fleetbo</b> à la racine de votre projet. </h5>
                        <br />
                        <h5 className='text-success fw-bold mt-3'> Connexion </h5>
                        <span className='title1'>
                            <span className='brand fw-bold text-decoration-none text-dark'> Connecter votre application à FleetBo </span> 
                        </span>
                        <h5 className='details '><i class="fa-solid fa-check me-2 mt-2"></i> Dans le dossier <b>fleetbo</b>, créez le fichier <b className='text-decoration-underline'>config.js</b>.</h5>
                        <h5 className='details '><i class="fa-solid fa-check me-2"></i> Copiez les paramètres de connexion envoyés par mail. </h5>
                        <h5 className='details'><i class="fa-solid fa-check me-2"></i>  Dans le fichier <b>config.js</b>, collez les paramètres de connexion. </h5> 
                        <br />
                        <h5 className='text-success fw-bold mt-3'> Authentification </h5>
                        <span className='title1'>
                            <span className='brand fw-bold text-decoration-none text-dark'> Connexion et se déconnexion en un clic </span> 
                        </span>
                        <h5 className='details '><i class="fa-solid fa-check me-2 mt-2"></i> Dans le dossier <b>fleetbo</b>, créez le fichier <b className='text-decoration-underline'>auth.js</b>.</h5>
                        <h5 className='details '><i class="fa-solid fa-check me-2 mt-2"></i> Dans le dossier <b>fleetbo</b>, créez le fichier <b className='text-decoration-underline'>logout.js</b>.</h5>
                        <h5 className='details '><i class="fa-solid fa-check me-2"></i> Copiez les paramètres d'authentification envoyés par mail. </h5>
                        <h5 className='details'><i class="fa-solid fa-check me-2"></i> Dans le fichier <b className='text-decoration-underline'>auth.js</b>, collez les paramètres d'authentification. </h5> 
                        <h5 className='details '><i class="fa-solid fa-check me-2"></i> Copiez les paramètres de déconnexion envoyés par mail. </h5>
                        <h5 className='details'><i class="fa-solid fa-check me-2"></i> Dans le fichier <b className='text-decoration-underline'>logout.js</b>, collez les paramètres de déconnexion. </h5> 
                        <br />
                        <h5 className='text-success fw-bold mt-3'> Notifications </h5>
                        <span className='title1'>
                            <span className='brand fw-bold text-decoration-none text-dark'> Push notifications de votre application </span> 
                        </span>
                        <h5 className='details '><i class="fa-solid fa-check me-2 mt-2"></i> Dans le dossier <b>fleetbo</b>, créez le fichier <b className='text-decoration-underline'>push.js</b>.</h5>
                        <h5 className='details '><i class="fa-solid fa-check me-2"></i> Copiez le code de fonction de notification push envoyés par mail. </h5>
                        <h5 className='details'><i class="fa-solid fa-check me-2"></i> Dans le fichier <b className='text-decoration-underline'>push.js</b>, collez le code de fonction de notification push. </h5> 
                        <br />
                        <h5 className='text-success fw-bold mt-3'> Publication </h5>
                        <span className='title1'>
                            <span className='brand fw-bold text-decoration-none text-dark'> Publiez votre application </span> 
                        </span>
                        <h5 className='details '><i class="fa-solid fa-check me-2 mt-2"></i> Redefinissez les liens de vos pages.</h5>
                        <h5 className='details '><i class="fa-solid fa-check me-2"></i> Exemple de lien FleetBo: https://NomDeDomaine.com/kws_code/Nom-de-la-page. </h5>
                        <h5 className='details '><i class="fa-solid fa-check me-2"></i> Exemple de lien d'entrée FleetBo: https://NomDeDomaine.com/kws_code/welcome. </h5>
                        <h5 className='details'><i class="fa-solid fa-check me-2"></i> Publiez votre application sur le serveur de votre choix avec des liens FleetBo. </h5> 

                    </div>

                </div>
                {/* 
                <div class="col-sm-5 box-features pt-3 ps-3 pe-3 pb-2 me-4">
                    <div className='col-md-12 div-details-welcome ps-2 pe-2 pt-3 pb-3'>
                        <h5 className='text-dark fw-bold mt-1'> Authentification </h5>
                        <div class="col-sm-12 bg-media ">
                            <video width="420" height="305" src="https://assets.codepen.io/6093409/hubspot-video-example.mp4" controls autoplay></video>
                        </div>
                        <br />
                        <h5 className='text-dark fw-bold mt-3'> Notifications </h5>
                        <div class="col-sm-12 bg-media ">
                            <video width="420" height="200" src="https://assets.codepen.io/6093409/hubspot-video-example.mp4" controls autoplay></video>
                        </div>
                    </div>
                </div>
                */}
            </div>
        </div>
        <br />

        </div>

        <div className='box-doc pb-2'>
            <div class="container">
                <div className='row justify-content-center '>
                    <div className='col-md-3 ps-3 pe-3 text-center'>
                        <p className='text-desc text-secondary fw-bold text-center'>Empowering Web Developers</p>
                        <h6 className='text-success' style={{ borderRadius: '7px'}}><b>Fleetbo</b> &copy; 2024</h6> 
                    </div>
                </div>
            </div>
        </div>
    </div>


       
  );
}

export default Mobile;
