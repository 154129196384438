import React, { useEffect, useState } from "react";
import { db, auth } from "./../firebase";
import { collection, getDoc, doc, onSnapshot } from "firebase/firestore";
import { useAuthState }  from 'react-firebase-hooks/auth';
import { useNavigate, Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import './../css/Home.css';
import './../css/ProgressBar.css';

import Navbar from './../navbars/NavbarProject';
import NavbarGenerate from './../navbars/NavbarGenerate';
import Dashboard from './Dashboard';



function Contacts() {

	//const { currentUser } = useAuth();
	const [data, setData] = useState([]);

	const [user, loading, error ] = useAuthState(auth);
	const navigate = useNavigate();

	const [contacts, setContacts] = useState(JSON.parse(localStorage.getItem('contacts')) || null);
	const [isLoading, setIsLoading] = useState(true);
	
	const handleLogout = () => {
        auth.signOut();
        navigate('/');
    };


    useEffect(() => {

		const fetchData = async () => {
			const id = 'WHsQQDGqPAWizwXL3EAg'; // Define id here
			const docRef = doc(db, "entreprises", id);
			const docSnap = await getDoc(docRef);
		  
			if (docSnap.exists()) {
			  const docData = docSnap.data();
			  const data = {
				nom: docData.nom, // Replace 'field1' with your actual field name
			  };
			  setData(data);
			  localStorage.setItem('Company', docData.nom);
			} else {
			  console.log("No such document!");
			}
		  };
		  
		fetchData();


		const collectionRef = collection(db, 'contacts');

		const unsubscribe = onSnapshot(collectionRef, (collectionSnap) => {
		  if (!collectionSnap.empty) {
			const newNotifications = collectionSnap.docs.map((doc) => ({
			  id: doc.id,
			  ...doc.data()
			}));
			
			const cachedData = JSON.parse(localStorage.getItem('contacts'));
			if (JSON.stringify(newNotifications) !== JSON.stringify(cachedData)) {
			  setContacts(newNotifications);
			  localStorage.setItem('contacts', JSON.stringify(newNotifications));
			}

			setIsLoading(false);
		  } else {
			alert('No such notifications!');
			console.log('No such notifications!');
			setIsLoading(false);
		  }
		});

		return () => unsubscribe();

	}, []);
	

    if (isLoading) {
        return <div className="App-Home"><><div className="fs-3 fw-normal text-dark p-5">
        <Spinner animation="grow" variant="warning" role="status">
            <span className="visually-hidden text-light">Loading...</span>
        </Spinner>
    </div></> </div>;
    }

    if (error) {
        return <div className='fs-6'>Error: {error}</div>;
    }



    return (
    <div className=""  style={{ background: '#02a049', overflowY: 'visible'  }}>
        
        <header className="App-Home"  style={{ overflowY: 'visible'  }}>
          <div className='col-md-7 pt-1 '  style={{ overflowY: 'visible'  }}>
		      <br />
              {data ? <h2 className='fw-bolder text-white text-center mt-3'>Fleetbo</h2> : <p>Loading...</p>} 
              <div className="container col-md-12 p-0 ">
                 
                    <div className="col-md-12 box-campagne bg-white ps-2  pe-2 pb-5 ">
                        <h5 className='text-dark fs-5 fw-bold mt-3 mb-3'>  
                            Developpeurs
                            <b className='text-success ms-2'><i className="fa fa-code"></i></b> 
							<Link onClick={handleLogout} className='ms-2'>
                                <b className='text-danger ms-2'><i className="fa fa-power-off"></i></b> 
                            </Link>
                        </h5>  
                        <div className='col-md-12 pt-3 ps-2 mb-2 dash'>
                            <div>
                                {/*<p>{user.email}</p> */}
                                {contacts.map((item) => (
                                    <div key={item.id}> 
                                        <div className='mb-1'>
                                            <h5 className='description'> {item.email} </h5>  
                                            <h5 className='target text-secondary fw-bold'> 
											    <a href='{item.linkedin}'>
												   <b className='me-1 text-success'>LinkedIn <i className="fa-solid fa-link me-1"></i></b>
												</a> 
												{item.country}   
											</h5>
                                            <h5 className='datepush'> Publiée le {item.contactDate.toDate().toLocaleString()}</h5>  
                                        </div>
                                        <hr></hr>
                                    </div>
                                ))}
                            </div>
                        </div>                              
                    </div>

              </div>
              <p className='text-desc text-center mt-1' style={{ fontSize: '14px'}}>The First App-as-a-Service.</p>
              <p className='text-success text-center text-light' style={{  fontSize: '12px'}}><b>Fleetbo</b> &copy; 2024</p> 
          </div>
        </header>
    </div>
    );
}

export default Contacts;
