import React, { useEffect, useState } from "react";
import './../css/Home.css';
import NavbarDash from './../navbars/NavbarDash';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import { db, auth } from "./../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useAuthState }  from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

function Dashboard() {

  //const { currentUser } = useAuth();
  const [data, setData] = useState([]);

  const [user, loading ] = useAuthState(auth);
  const navigate = useNavigate();
  if(!user){
    navigate('/login');
  }

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "contacts"));
      setData(querySnapshot.docs.map(doc => doc.data()));
     
    };
   
    fetchData();

  }, []);

  return (
    <header className="App-header">
        <NavbarDash />
    <div class="col-md-8 box ps-3 pe-3 pb-5 ">
        <h5 className='text-dark fs-4 fw-bold mt-3 mb-3'>  
            Contacts
            <a href='/notification' className='ms-2'>
                  <b className='text-success ms-2'><i className="far fa-paper-plane"></i></b> 
            </a>
        </h5>  
        <div className='col-md-12 div-details ps-2 pe-2 pt-4' >
        {loading? (
            <div> loading </div>
          ): (
            <>
            {user? (
              <div>
                {/*<p>{user.email}</p> */}
                    {data.map((item, index) => (  
                        <div key={item.id}> 
                            {item.status == false ? (
                                <div className='mb-4'>
                                    <h5 className='description'>
                                        {item.text}
                                    </h5>  
                                    <h5 className='target'> 
                                        <b className=''>2579 / 3000</b> users touchés  
                                    </h5>
                                    <h5 className='datepush'> {item.dateCreated}</h5>  
                                    <a href="mailto:contact@fleetbo.io" className="btn btn-success text-center fs-6">
                                        Répondre <i class="fa-solid fa-reply"></i>
                                    </a>
                                    <button href="mailto:contact@fleetbo.io" className="btn fs-4 text-danger text-center ms-2" >
                                        <i class="fa-regular fa-circle-xmark fw-bolder"></i>
                                    </button>
                                </div>
                            ):(
                                   <i class="fa-solid fa-check text-success fs-6 ms-2"></i>
                            )}
                            <hr></hr>
                        </div>
                    ))}
              </div>
            ):(
              <div>
                <h5>Pas connecté. veuillez vous connecter</h5>
                <a href='/login' className='connexion'>Se Connecter</a>
                <br /> <br />
              </div>
            )}
            </>
        )}   
        </div>                              
    </div>
    </header>      
  );
}

export default Dashboard;
