import logo1 from './img/logo1.png';
import './css/Home.css';
import './css/ProgressBar.css';
import { Player } from 'video-react';

import Navbar from './navbars/NavbarProject';
import Spinner from 'react-bootstrap/Spinner';
import React, { useEffect, useState } from "react";
import { db, auth } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import { useAuthState }  from 'react-firebase-hooks/auth';
import { Navigate, useNavigate } from 'react-router-dom';



function Redirect() {

  //const { currentUser } = useAuth();
  const [user, loading, error ] = useAuthState(auth);
  const navigate = useNavigate();

  if(!user){
    navigate('/login');
  } else {
    navigate('/home')
  }

  return (
    <div className="App">
      <Navbar />
      <header className="App-header bg-home">
        <div className='col-md-7 pt-1 '>
            <div className="container col-md-12 p-0 ">
                <div className="col-md-12 p-0 justify-content-center">
                   <div className='text-center justify-content-center'>
                      <h3 className='title text-light text-center'>FleetBo.io</h3>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                   </div>
                </div>
            </div>
        </div>
      </header> 
    </div>
  );
}

export default Redirect;
