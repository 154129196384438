
import './../css/Welcome.css';
import { Player } from 'video-react';
import { useState } from 'react';

import Navbar from './../navbars/NavbarForm';
import { auth, db } from './../../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuth } from '../firebase/AuthContext';
import {
    collection,
    addDoc,
    getDocs,
    updateDoc,
    deleteDoc,
    doc
  } from 'firebase/firestore';
import { useForm } from 'react-hook-form';



function Message() {


   const [message, setMessage] = useState('');

   const [user, setUsers] = useState(null);
   const [error, setError] = useState('');

   const [name, setName] = useState('');
   const [country, setCountry] = useState('');
   const [category, setCategory] = useState('');
   const [email, setEmail] = useState('');

   const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage('');
  
      try {
        await addDoc(collection(db, 'inscriptions'), { content: name, country, category, email });
        setMessage('Merci de votre contact ! Nous vous répondrons sous peu.');
        setName(''); // Clear the input field after successful submission
        setCountry('');
        setCategory('');
        setEmail('');
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };


  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <div className='col-md-11 ms-2 me-2 row justify-content-center'>
            <div class="col-sm-6 p-2">
                <h5 className='mt-5 ms-2'> FleetBo </h5>
                <div className='col-md-12 ps-2 pe-2 pt-2 pb-2 '> 
                    <p className=''>
                      <span className='title1'>
                          <span className='brand me-2'>
                            Navigateurs <i class="fa-brands fa-edge-legacy ms-1 brand"></i> <i class="fa-brands fa-firefox-browser ms-1 brand"></i>
                          </span> 
                          | 
                          <span className='brand ms-2'>Téléchargements</span> 
                          <i class="fa-brands fa-google-play brand ms-1"></i> <i class="fa-brands fa-apple brand"></i>
                      </span>
                      <br />
                      <div className=' pt-2 pb-2'>
                      <form onSubmit={handleSubmit}>
                        <div className='justify-content-center'>
       
                                <div className=' mb-2'>
                                    <label className='ps-0 fs-6 label'>Pseudo</label>
                                    <input type="text" 
                                           className='form-control' placeholder=''
                                        
                                            required
                                        />
                                </div>
                            
                                <div className=' mb-4'>
                                    <label className='ps-0 fs-6 label'>Notifiez vos utilisateurs (75 caractères maximum)</label>
                                    <textarea type="text" 
                                              className='form-control'
                                            
                                              placeholder="Message..." maxLength='75'
                                              id="exampleFormControlTextarea1" rows="3"
                                              required
                                    >
                                    </textarea>    
                                </div>

                                <div className='mb-3 justify-content-center d-flex'>
                                    <button type='submit' className='connexion' >  
                                        Envoyer <i className="fas fa-paper-plane me-3 fw-bold"></i> 
                                    </button>
                                </div>
                        </div>
                      </form>
                      </div>
                      
                      <b className='title2 fs-6'>Ce message sera adressé à un utilisateur. </b>

                    </p>
                </div>
            </div>
            <div class="col-sm-5 bg-media ">
                 <video width="420" height="305" src="https://assets.codepen.io/6093409/hubspot-video-example.mp4" controls autoplay></video>
            </div>
        </div>
        {/*
        <div className='ps-3 pe-3'>
           <p className='text-desc'>Transformez vos sites web en Appli non téléchargeable.</p>
        </div>
        */}
      </header>

      <footer className="footer pt-2 pb-1">
        <div>
           <h6 className='text-success'><b>FleetBo</b> &copy; 2024</h6> 
        </div>
      </footer>
      
    </div>
  );
}

export default Message;
