import React, { useEffect, useState } from "react";
import './../css/Welcome.css';
import teaser from './../video/teaser.mp4';
import Navbar from './../navbars/NavbarForm';
import { auth, db } from './../../firebase';
import { useAuth } from '../firebase/AuthContext';
import {
    collection,
    addDoc,
    getDocs,
    updateDoc,
    deleteDoc,
    doc
  } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { useAuthState }  from 'react-firebase-hooks/auth';
import { Navigate, useNavigate } from 'react-router-dom';

function Notification() {


   const [message, setMessage] = useState('');

   //const [user, setUsers] = useState(null);
   //const [error, setError] = useState('');

  
   const [user, loading, error ] = useAuthState(auth);
   const navigate = useNavigate();
   if(!user){
    navigate('/login');
   }

   const [content, setContent] = useState('');
   //const entrepriseId = user.uid;
   const date         = new Date();

   const dateCreated  = date.toString();


   const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage('');
  
      try {
        const entrepriseId = user.uid
        await addDoc(collection(db, 'Goupnotifs'), { content: content, entrepriseId, dateCreated });
        setMessage('Message publié !');
        setContent(''); // Clear the input field after successful submission
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
  };


  useEffect(() => {
     //alert('User: ' + user.uid)
  });


  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <div className='col-md-11 ms-2 me-2 row justify-content-center'>
            <div className="col-sm-6">
              <br />
                <h5 className='mt-5 fw-bolder'> Fleetbo </h5>
                <div className='col-md-12 pt-1 pb-2 '> 
                    <p className=''>
                      <span className='title1'>
                          <span className='brand text-white me-2'> Engage your users or customers </span> 
                      </span>
                      <br />
                      <div className=''>
                      <form onSubmit={handleSubmit}>
                        <div className='justify-content-center'>
                                <div className=' mb-3'>
                                    <label className='ps-0 pb-1 fs-6 label'>Your message (90 characters maximum)</label>
                                    <textarea type="text" 
                                              className='form-control'
                                              value={content}
                                              onChange={(e) => setContent(e.target.value)}
                                              placeholder="Message..." maxLength='90'
                                              id="exampleFormControlTextarea1" rows="2"
                                              required
                                    >
                                    </textarea>    
                                </div>
                                {user ? (
                                <div className='mb-2 justify-content-center d-flex'>
                                    <button type='submit' className='connexion' >  
                                        Publish <i className="fas fa-paper-plane me-3 fw-bold"></i> 
                                    </button>
                                    <br />
                                </div>
                                ) : (
                                <div className='mb-2 justify-content-center d-flex'>
                                  <h5 className='' >  Please login  </h5>
                                </div>
                                )}  
                                {error && <p>{error}</p>}
                                {message && 
                                <p className='fs-6'>{message}</p>
                                }
                        </div>
                      </form>
                      </div>
                      <b className='title2 fs-6'>This message will be sent to all your users </b>
                    </p>
                </div>
            </div>
            <div className="col-sm-5 bg-media ">
			    <video
                    poster="https://mir-s3-cdn-cf.behance.net/project_modules/disp/583b6136197347.571361641da25.gif"
                         //onLoad={handleImageLoaded} // Appelé une fois l'image complètement chargée
                         //style={{ display: loading ? 'none' : 'block' }} // Masquer l'image jusqu'à son chargement
                    src={teaser} 
                    muted autoplay='true' playsinline='true' 
                    loop='true' id="vid">  
                </video>
            </div>
        </div>
        {/*
        <div className='ps-3 pe-3'>
           <p className='text-desc'>Transformez vos sites web en Appli non téléchargeable.</p>
        </div>
        */}
      </header>

    </div>
  );
}

export default Notification;
